









































































import {
  defineComponent,
  PropType,
  ref,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api';
import { SfButton, SfLoader, SfProductCard } from '@storefront-ui/vue';

import AddProductItemToCartDialog from '~/components/AddProductItemToCartDialog.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useImage } from '~/composables';
import { useAddToCart } from '~/helpers/cart/addToCart';
import CategoryProductPrice from '~/modules/catalog/category/components/views/CategoryProductPrice.vue';
import type { Product } from '~/modules/catalog/product/types';

import { useProductsWithCommonProductCardProps } from './useProductsWithCommonCardProps';

export default defineComponent({
  components: {
    CategoryProductPrice,
    SfProductCard,
    SkeletonLoader,
    SfButton,
    SfLoader,
    AddProductItemToCartDialog,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    pricesLoaded: Boolean,
    loading: Boolean,
  },
  emits: ['click:wishlist', 'click:add-to-cart'],
  setup(props) {
    const {
      imageSizes: { productCard: imageSize },
    } = useImage();
    const { app } = useContext();
    const currentClick = ref(null);
    const isOpen = ref<Boolean>(false);
    const { isDesktop } = app.$device;

    const nowProduct = ref(null);

    const {
      loading: isCartLoading,
      addItemToCart,
      isInCart,
      error,
    } = useAddToCart();

    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } =
      useProductsWithCommonProductCardProps(products);

    const handleAddItemToCart = async (product, i) => {
      currentClick.value = i;
      await addItemToCart({
        product,
        quantity: 1,
      });
      if (isInCart(product) && !error.value?.addItem?.message) {
        isOpen.value = true;
        nowProduct.value = product;
      }
      currentClick.value = null;
    };

    const closedialog = () => {
      isOpen.value = false;
    };

    return {
      isCartLoading,
      addItemToCart,
      imageSize,
      productsWithCommonProductCardProps,
      handleAddItemToCart,
      currentClick,
      nowProduct,
      closedialog,
      isOpen,
    };
  },
});
