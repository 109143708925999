





















































import {
  computed,
  defineComponent,
  PropType,
  toRef,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { SfButton, SfHeading, SfModal, SfPrice } from '@storefront-ui/vue';

import { useImage } from '~/composables';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import productGetters, {
  getName as getProductName,
  getPrice as getProductPrice,
} from '~/modules/catalog/product/getters/productGetters';
import { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'AddProductItemToCartDialog',
  components: {
    SfButton,
    SfHeading,
    SfModal,
    SfPrice,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: () => false,
    },
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter();
    const { app } = useContext();
    const product = toRef(props, 'product');
    const { productGallery } = useProductGallery(product);
    const productPrice = computed(() => getProductPrice(props.product).regular);
    const productSpecialPrice = computed(
      () => getProductPrice(props.product).special
    );
    const productFinalPrice = computed(
      () => getProductPrice(props.product).final
    );
    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };
    const { getMagentoImage } = useImage();
    const { isDesktop } = app.$device;
    return {
      // productShortDescription,
      productGallery,
      getProductName,
      productPrice,
      productSpecialPrice,
      productFinalPrice,
      goToCart,
      productGetters,
      getMagentoImage,
      isDesktop,
    };
  },
});
