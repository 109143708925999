import { computed, Ref } from '@nuxtjs/composition-api';

import { useImage } from '~/composables';
import type { UseProductGalleryInterface } from '~/modules/catalog/product/composables/useProductGallery/useProductGallery';
import { getGallery as getProductGallery } from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';

/**
 * The `useProductGallery()` composable allows building product's gallery data structure.
 *
 * See the {@link UseProductGalleryInterface} page for more information.
 */
export function useProductGallery(product: Ref<Product>, imgPlaceholder = ''): UseProductGalleryInterface {
  const { getMagentoImage, imageSizes } = useImage();

  const productGallery = computed(() =>
    getProductGallery(product.value).map((img) => ({
      mobile: { url: `${getMagentoImage(img.small)}?resize=p_2,w_800,h_800&f=webp` },
      desktop: { url: `${getMagentoImage(img.normal)}?resize=p_2,w_800,h_800&f=webp` },
      big: { url: `${getMagentoImage(img.big)}?resize=p_2,w_800,h_800&f=webp` },
      alt: product.value.name,
      placeholder: imgPlaceholder,
    }))
  );
  console.log(productGallery, 'productGalleryproductGallery');

  return {
    productGallery,
    imageSizes,
  };
}

export default useProductGallery;
export * from './useProductGallery';
